import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: calc(100% - 15px);
    margin-bottom: 24px;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: ${(props) => props.theme.primary3Color};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  margin: 0 0 7px 2px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export { Wrapper, Container, Title, Row };
