import styled from 'styled-components';

const SelectWrapper = styled.div`
  display: block;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: calc(100% + 15px);
  }
`;

const Label = styled.p`
  display: none;
  font-size: 9px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const ControlContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-height: 48px;
  }

  & > div:first-child {
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      max-height: 48px;
    }
    & > div:first-child {
      display: inline-block !important;
      white-space: nowrap;
      flex-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
`;

const SelectStyles = {
  controlNormal: (styles) => ({
    ...styles,
    '&:hover': {
      borderColor: '#E6E6E6',
    },
    cursor: 'pointer',
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    fontSize: '9px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'black',
    height: '55px',
    paddingLeft: '16px',
    paddingRight: '16px',
  }),
  controlSmall: (styles) => ({
    ...styles,
    '&:hover': {
      borderColor: '#E6E6E6',
    },
    cursor: 'pointer',
    border: '1px solid #E6E6E6',
    borderRadius: '4px',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    fontSize: '9px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'black',
    height: '55px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxHeight: '48px',
  }),
  option: (styles, { isFocused, isSmall }) => ({
    ...styles,

    '&:hover': {
      backgroundColor: '#E6E6E6',
    },

    '&:first-of-type': {
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },

    '&:last-of-type': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },

    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '500',
    background: 'white',
    maxHeight:
      isSmall && window.matchMedia('(max-width: 768px)').matches
        ? '46px'
        : 'unset',
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    padding: '14px 16px',
    margin: 0,
    cursor: 'pointer',
  }),
  input: () => ({
    marginLeft: '-7px',
    marginTop: '2px',
    border: 'none',
    fontSize: '9px',
  }),
  placeholder: (styles) => ({
    ...styles,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    border: 'none',
    color: (props) => props.theme.black,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  valueContainer: (styles, { selectProps }) => ({
    ...styles,
    display: 'flex',
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '16px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    paddingLeft: '0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: selectProps.isMulti ? 'inline-block' : 'flex',
  }),
  dropdownIndicator: (base, { selectProps }) => ({
    ...base,
    transition: 'all .2s ease',
    borderWidth: '8px 6.5px 0 6.5px',
    height: '0',
    width: '0',
    padding: '0',
    borderColor: '#000000 transparent transparent transparent',
    borderStyle: 'solid',
    bottom: selectProps.menuIsOpen ? '0px' : '-8px',
    right: '0',
    transform: `rotateZ(${selectProps.menuIsOpen ? '180deg' : '0deg'})`,
    svg: {
      display: 'none',
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: '4px',
    borderColor: '#E6E6E6',
    borderWidth: '1px',
    borderStyle: 'solid',
    boxShadow: 'none',
    marginTop: '4px',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth: '95%',
  }),
};

export { SelectWrapper, ControlContainer, SelectStyles, Label };
