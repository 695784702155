import { useEffect, useRef, Fragment } from 'react';
import { withTheme } from 'styled-components';

import FullArrow from 'components/icons/FullArrow';
import Link from 'components/ui/Link';

import {
  Delimiter,
  PathPart,
  PathWrapper,
  Content,
  ImageWrapper,
  Image,
  BreadcrumbsWrapper,
} from './Breadcrumbs.styles';
import { VisibilityContainer } from '../../utils/styles';

const Breadcrumbs = ({
  imageUrl = null,
  pathParts = [],
  theme,
  onImageLoad = () => {},
  isImageSmall = false,
  isWithoutPathParts = false,
  backgroundMap = null,
  isBannerMap = false,
  hideOnMobile = false,
}) => {
  const imgRef = useRef(null);

  useEffect(() => {
    onImageLoad();
  }, [imageUrl]);

  return (
    <BreadcrumbsWrapper>
      <ImageWrapper
        isImageSmall={isImageSmall}
        isBannerMap={isBannerMap}
        $hideOnMobile={hideOnMobile}
      >
        {backgroundMap || (
          <Image ref={imgRef} src={imageUrl} fill priority alt='hero_img' />
        )}
      </ImageWrapper>

      {!isWithoutPathParts ? (
        <VisibilityContainer $md $lg>
          <Content id='scroll'>
            <PathWrapper>
              {pathParts.map((el, key) => (
                <Fragment key={el.id}>
                  <Link href={el.href} route={el.route} params={el.params}>
                    <PathPart>{el.name}</PathPart>
                  </Link>
                  {key === pathParts.length - 1 ? null : (
                    <Delimiter>
                      <FullArrow fill={theme.primary1Color} />
                    </Delimiter>
                  )}
                </Fragment>
              ))}
            </PathWrapper>
          </Content>
        </VisibilityContainer>
      ) : null}
    </BreadcrumbsWrapper>
  );
};

export default withTheme(Breadcrumbs);
