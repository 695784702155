import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 50px);
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.primary2Color};
  transform: translateX(100vw);
  transition: 0.5s ease transform;

  ${({ visible }) =>
    visible &&
    css`
      transform: translateX(0);
    `}
`;

export const RelativeContainer = styled.div`
  flex-grow: 1;
  padding: 30px 20px 0;
  position: relative;
  max-height: 100%;
  overflow: auto;
`;

export const PhoneButton = styled.a`
  position: fixed;
  background: ${(props) =>
    props.active ? props.theme.secondary12Color : props.theme.secondary15Color};
  border: 0;
  top: 8px;
  right: 62px;
  width: 31px;
  z-index: 99;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease all;

  svg {
    width: 20px;
    height: auto;

    path {
      transition: 0.2s ease all;
    }

    ${(props) =>
      props.active &&
      css`
        width: 17px;

        path {
          fill: ${props.theme.secondary3Color};
        }
      `}
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: space-between;
`;

export const Container = styled.div`
  font-weight: 600;
  flex-basis: calc(50% - 5px);
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 8px;
  }
`;

export const TextContainer = styled.div`
  margin-left: 5px;
  white-space: nowrap;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-height: 50px;
  align-items: flex-start;

  svg {
    margin-top: 0;
  }
`;

export const Text = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${(props) => props.theme.primary3Color};
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
`;

export const AddressDescription = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  margin-top: 3px;
  color: ${(props) => props.theme.primary5Color};
`;

export const PhoneNumberButton = styled.a`
  background: ${(props) => props.theme.secondary7Color};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 16px;
  margin-top: 16px;
  color: ${(props) => props.theme.primary3Color};
  text-decoration: none;

  svg {
    margin-right: 8px;
  }
`;

export const ContactPeopleContainer = styled(AddressContainer)`
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const EmailButton = styled(PhoneNumberButton)`
  letter-spacing: -0.5px !important;
  font-weight: 500;
`;

export const FooterContainer = styled.div`
  & > div {
    position: static;
  }
  position: static;
  width: 100%;
`;

export const PostalCodeAndAddress = styled(AddressDescription)`
  margin-bottom: 8px;
`;
