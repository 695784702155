import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.isDisabled && css`
    opacity: 0.5;
    cursor: default;
  `}
`;

const OuterElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isAlternativeDesign ? '18px ' : '28px')};
  min-width: ${(props) => (props.isAlternativeDesign ? '18px ' : '28px')};
  border-color: ${(props) => (props.isChecked && props.isAlternativeDesign ? `${props.theme.primary3Color} !important` : props.theme.secondary7Color)};
  border: ${(props) => (props.isAlternativeDesign ? `2px solid ${props.theme.secondary18Color}` : `2px solid ${props.theme.secondary7Color}`)};
  border-radius: ${(props) => (props.isAlternativeDesign ? '4px' : '0')};
  background: ${(props) => props.theme.primary2Color};
  margin: ${(props) => (props.isAlternativeDesign ? '0 12px 0 0 !important' : '0 16px 0 0')};
  margin: ${(props) => (props.reverse ? '0 0 0 25px' : '0 16px 0 0')};
  cursor: pointer;
  ${({ isSmall }) => isSmall && css`
    height: 15px;
    min-width: 15px;
  `};

  ${(props) => props.isDisabled && css`
    cursor: default;
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: ${(props) => (props.reverse ? '0 0 0 20px' : '0 20px 0 0')};
    height: ${(props) => (props.isAlternativeDesign ? '18px ' : '25px')};
    min-width: ${(props) => (props.isAlternativeDesign ? '18px ' : '25px')};
    border-width: ${(props) => (props.isAlternativeDesign ? '2px ' : '1px')};
  }
`;

const InnerElement = styled.div`
  width: 8px;
  height: 15px;
  border: solid ${(props) => props.theme.primary3Color};
  border-width: 0 1px 1px 0;
  transform: rotateZ(50deg);
  margin-bottom: 5px;
  opacity: 0;
  ${({ isSmall }) => isSmall && css`
    width: 3px;
    height: 9px;
    margin-bottom: 2.5px;
  `}

  ${(props) => props.active && css`
    opacity: 1;
  `}

  ${(props) => props.isAlternativeDesign && css`
    width: 4px;
    height: 11px;
    border-width: 0 2px 2px 0;
    transform: rotateZ(46deg);
    margin-bottom: 4px;
  `}
`;

export { Wrapper, OuterElement, InnerElement };
