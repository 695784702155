import FullArrow from 'components/icons/FullArrow';
import PhoneEmptyIcon from 'components/icons/PhoneEmpty';
import FooterCopyright from 'components/FooterCopyright/FooterCopyright';

import { SCROLLABLE_ELEMENT_CLASS_NAME } from 'utils/bodyScrollLock';
import Contact from 'components/content/SalesDepartmentContacts/components/ContactList/Contact/Contact';
import {
  Wrapper,
  AddressContainer,
  Container,
  Row,
  Text,
  TextContainer,
  PhoneNumberButton,
  AddressDescription,
  ContactPeopleContainer,
  FooterContainer,
  PhoneButton,
  RelativeContainer,
  PostalCodeAndAddress,
} from './ContactDetails.styles';

const ContactDetails = ({
  onPhoneIconClick,
  opened = false,
  contactItems,
  contactPeople,
}) => (
  <>
    <PhoneButton onClick={onPhoneIconClick} active={opened}>
      <PhoneEmptyIcon />
    </PhoneButton>
    <Wrapper visible={opened}>
      <RelativeContainer className={SCROLLABLE_ELEMENT_CLASS_NAME}>
        <AddressContainer>
          {contactItems
            .filter((elem) => elem.phone)
            .map((elem, key) => (
              <Container key={key}>
                <Row>
                  <TextContainer>
                    <Text>
                      {' '}
                      <FullArrow width='6px' height='100%' />
                      {elem.address}
                    </Text>
                    <AddressDescription>{elem.description}</AddressDescription>
                    {!!elem.postalCodeAndAddress && (
                      <PostalCodeAndAddress>
                        {elem.postalCodeAndAddress}
                      </PostalCodeAndAddress>
                    )}
                  </TextContainer>
                </Row>
                <PhoneNumberButton href={`tel:${elem.phone}`}>
                  <PhoneEmptyIcon width='16px' height='100%' />
                  {elem.phone}
                </PhoneNumberButton>
              </Container>
            ))}
        </AddressContainer>
        <ContactPeopleContainer>
          {contactPeople.map((contactPerson, key) => (
            <Contact {...contactPerson} key={key} />
          ))}
        </ContactPeopleContainer>
        <FooterContainer>
          <FooterCopyright />
        </FooterContainer>
      </RelativeContainer>
    </Wrapper>
  </>
);

export default ContactDetails;
