import React, { useRef, useEffect, useState } from 'react';

import Link from 'components/ui/Link';

import isPromotionActive from 'utils/isPromotionActive';
import { VisibilityContainer } from 'utils/styles';
import { FormattedMessage } from 'react-intl';

import messages from './Slide.messages';

import {
  Image,
  Wrapper,
  ImageWrapper,
  PromotionText,
  PromotionWrapper,
  InfoOverlayWrapper,
  InfoOverlayContainer,
  InfoOverlay,
  InfoOverlayTitle,
  InfoOverlayDescription,
  InfoOverlayButton,
} from './Slide.styles';

const handleImageLoaded = () => {
  window.dispatchEvent(new Event('resize'));
};

const Slide = ({
  imageUrl,
  link = null,
  title = null,
  description = null,
  promo = null,
  showModal = undefined,
  isHalfWidth = false,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(!imageUrl);

  const imageRef = useRef(null);

  const onLoad = () => {
    setIsImageLoaded(true);
  };

  let imageComponent = (
    <Image
      ref={imageRef}
      src={imageUrl}
      alt='slide'
      draggable={false}
      onLoad={handleImageLoaded}
      $withPointer={!!showModal}
      onClick={() => {
        if (showModal) {
          showModal(imageUrl);
        }
      }}
      $isHalfWidth={isHalfWidth}
      width={0}
      height={0}
      sizes='100vw'
      style={{ width: '100%', height: 'auto' }}
      priority
    />
  );

  if (link && link.href) {
    imageComponent = (
      <Link
        href={link.href || ''}
        params={link.params}
        target='_top'
        draggable={false}
      >
        {imageComponent}
      </Link>
    );
  }

  useEffect(() => {
    if (imageRef.current) {
      onLoad();
    }
  }, [imageUrl]);

  return (
    <Wrapper>
      <ImageWrapper $isImageLoaded={isImageLoaded}>
        {imageComponent}
      </ImageWrapper>
      {promo && isPromotionActive(promo.entity.date) ? (
        <PromotionWrapper route='promotionsList' params={{ page: 1 }}>
          <PromotionText>{promo.entity.name}</PromotionText>
        </PromotionWrapper>
      ) : null}
      <VisibilityContainer $lg>
        {title && (
          <InfoOverlayWrapper>
            <InfoOverlayContainer>
              <InfoOverlay>
                <InfoOverlayTitle
                  dangerouslySetInnerHTML={{ __html: title }}
                ></InfoOverlayTitle>
                {description && (
                  <InfoOverlayDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></InfoOverlayDescription>
                )}
                <Link
                  href={link.href}
                  route={link.route}
                  params={link.params}
                  target='_top'
                  draggable={false}
                >
                  <InfoOverlayButton>
                    <FormattedMessage {...messages.seeMore} />
                  </InfoOverlayButton>
                </Link>
              </InfoOverlay>
            </InfoOverlayContainer>
          </InfoOverlayWrapper>
        )}
      </VisibilityContainer>
    </Wrapper>
  );
};

export default Slide;
