import Link from 'next/link';

import { useDispatch } from 'react-redux';
import { usePathname } from 'next/navigation';
import { StyledLink } from './Link.styles';
import Router from 'next/router';
import { resolveHref } from 'next/dist/client/resolve-href';

function AliasLink({
  children,
  href = '',
  scroll = true,
  params = {},
  active = true,
  className = '',
  ...props
}) {
  const dispatch = useDispatch();
  const currentPathname =
    typeof window !== 'undefined'
      ? `${usePathname()}${window?.location?.search}`
      : usePathname();
  const [, resolvedURL] = resolveHref(Router, href, true);
  const destinationPathname = href.pathname ? resolvedURL.split('#')[0] : href;

  return (
    <StyledLink {...props}>
      {active ? (
        <Link
          href={href}
          params={params}
          scroll={!!scroll}
          className={className}
          onClick={() => {
            if (currentPathname !== destinationPathname) {
              dispatch({ type: 'SHARED/SHOW_LOADER' });
            }
          }}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </StyledLink>
  );
}

export default AliasLink;
