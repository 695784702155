import { Wrapper, OuterElement, InnerElement } from './Checkbox.styles';

const Checkbox = ({
  isCheck = null,
  onClick,
  isSmall = false,
  reverse = false,
  isAlternativeDesign = false,
  children = null,
  isDisabled = false,
}) => (
  <Wrapper onClick={!isDisabled ? onClick : null} isDisabled={isDisabled}>
    <OuterElement
      isSmall={isSmall}
      reverse={reverse}
      isAlternativeDesign={isAlternativeDesign}
      isChecked={!isDisabled && isCheck}
      isDisabled={isDisabled}
    >
      <InnerElement
        active={isCheck}
        isSmall={isSmall}
        isAlternativeDesign={isAlternativeDesign}
      />
    </OuterElement>
    {children}
  </Wrapper>
);

export default Checkbox;
