import { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Select, { components } from 'react-select';
import find from 'lodash/find';

import {
  SelectWrapper,
  ControlContainer,
  SelectStyles,
  Label,
} from './Select.styles';
import { formatValues } from './utils';
import messages from './Select.messages';
import Checkbox from 'components/forms/Option/components/Checkbox';
import { CheckboxField } from 'components/content/SearchForm/SearchForm.styles';

const ControlComponent = (props) => (
  <ControlContainer id='control-container'>
    <components.Control {...props} />
  </ControlContainer>
);

const Option = (props) => {
  return (
    <div>
      <components.Option {...props} isSmall>
        <CheckboxField $smallMobileRowGap $isWithoutBottomSpacing>
          <Checkbox
            isAlternativeDesign
            isCheck={props.isSelected}
            onClick={() => null}
          />{' '}
          <label>{props.label}</label>
        </CheckboxField>
      </components.Option>
    </div>
  );
};

const SelectOption = ({
  values,
  selectedOption = undefined,
  placeHolder = null,
  intl,
  onChange = () => {},
  onMenuClose = () => {},
  name = null,
  withEmptyOption = false,
  optionComponent = null,
  height = null,
  isMulti = false,
  isSmall = false,
  selectedOptionsLabelLimit = null,
}) => {
  const options = formatValues(
    values,
    withEmptyOption,
    intl.formatMessage(messages.all)
  );

  const selected = find(options, (e) => e.value === selectedOption) || null;
  const selectedMultiple = [];
  const placeholder = placeHolder || intl.formatMessage(messages.all);
  const components = {};
  if (isMulti) {
    selectedOption.forEach((item) => {
      selectedMultiple.push(find(options, (e) => e.value === item));
    });
  }

  const MultiValueContainer = (props) => {
    return (
      <components.MultiValueContainer {...props}>
        <label>{props.label}</label>
      </components.MultiValueContainer>
    );
  };

  const MultiValue = (props) => {
    const numberOfSelectedItems = selectedMultiple.length;
    let value = props.children;

    if (props.index !== 0) {
      value = `\u00A0 ${value}`;
    }

    if (props.index !== numberOfSelectedItems - 1) {
      value = `${value},`;
    }

    if (
      (selectedOptionsLabelLimit || selectedOptionsLabelLimit === 0) &&
      props.index >= selectedOptionsLabelLimit
    ) {
      return null;
    }
    return value;
  };

  if (isMulti) components.MultiValueContainer = MultiValueContainer;
  if (isMulti) components.MultiValue = MultiValue;
  if (isMulti) components.Option = Option;
  if (isMulti) components.Control = ControlComponent;
  if (optionComponent) components.Option = optionComponent;

  const customHeightStyles = {
    control: () => ({
      height: `${height}px`,
    }),
  };

  useEffect(() => {
    // Scroll to the left when select is opened. Otherwise in
    // a multiselect it will scroll to the right and hide chosen options

    if (isMulti) {
      var element = document.querySelector(
        '#control-container > div > div > input[id^="react-select-"]'
      );

      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type === 'attributes') {
            const isSelectOpened =
              mutation.target.getAttribute('aria-expanded');

            if (isSelectOpened) {
              document.querySelector(
                '#control-container > div > div'
              ).scrollLeft = 0;
            }
          }
        });
      });

      observer.observe(element, {
        attributes: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <SelectWrapper>
      <Label>{name}</Label>
      <Select
        isSearchable={false}
        isClearable={false}
        options={options}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        blurInputOnSelect={!isMulti}
        noOptionsMessage={() => intl.formatMessage(messages.noOptions)}
        placeholder={placeholder}
        styles={{
          ...customHeightStyles,
          ...SelectStyles,
          control: isSmall
            ? SelectStyles.controlSmall
            : SelectStyles.controlNormal,
        }}
        onChange={onChange}
        onMenuClose={onMenuClose}
        value={isMulti ? selectedMultiple : selected}
        components={components}
        height={height}
        className='react-select'
        isMulti={isMulti}
      />
    </SelectWrapper>
  );
};

export default injectIntl(SelectOption);
