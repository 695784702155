import styled, { css } from 'styled-components';
import { ContainerMain } from 'utils/styles';
import { Tab, TabList, TabPanel } from 'react-tabs';
import RoundedTriangleIcon from 'components/icons/RoundedTriangle';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  background: ${(props) => props.theme.primary2Color};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    margin-bottom: 38px;
    margin-bottom: ${(props) => {
      if (props.$numberOfBadges < 1) {
        return '38px';
      } else {
        return Math.ceil(props.$numberOfBadges / 3) * 39 + 38 + 'px';
      }
    }};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  margin-bottom: 4px;
  justify-content: ${(props) =>
    props.jcFlexStart ? 'flex-start' : 'space-around'};
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: ${(props) => (props.$smallMobileRowGap ? '6px' : '24px')};
    flex-wrap: nowrap;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-wrap: wrap;
    row-gap: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 0;
    row-gap: 12px;
  }
`;

const InvestmentRow = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  max-width: 550px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 65px;
  margin-top: -5px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 20px;
    margin-top: -10px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    &:nth-of-type(1) {
      flex-direction: column;
    }

    &:nth-of-type(2) {
      flex-direction: column-reverse;
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-basis: 30%;
`;

const SingleField = styled(ColumnContainer)`
  flex-direction: column;
  align-items: flex-start;
  max-width: 262px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: calc(50% - 12px);
    min-width: calc(50% - 12px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: -10px;
    }
  }

  & > div > div:last-child > div {
    margin-right: 15px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      margin-right: 0;
    }
  }
`;

const InvestmentsContainer = styled(SingleField)`
  flex-basis: 75%;
  max-width: 224px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const InvestmentColumn = styled.div`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-basis: 33.3%;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    & > label {
      margin-top: 0;
    }
  }
`;

const Margin = styled.div`
  display: flex;
  width: 100%;
  margin-left: 25px;

  &:nth-of-type(1) {
    justify-content: flex-start;
  }

  &:nth-of-type(2) {
    justify-content: flex-end;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-left: 0;
  }
  ${(props) =>
    props.rightMargin &&
    css`
      margin-left: 0;
      margin-right: 25px;
    `}
`;

const FieldWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 16px;

    .react-select {
      & > div:first-of-type {
        height: 61px;
      }
    }

    & > div {
      width: 100%;
    }
  }
`;

const ScrollingAnchor = styled.div`
  background-color: transparent;
  width: 100%;
  height: 10px;
  position: absolute;
  top: -38px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const ScrollingAnchorAdvancedFilters = styled.div`
  background-color: transparent;
  width: 100%;
  position: absolute;
  top: -50px;
  display: none;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`;

const ChooseInvestments = styled.p`
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  flex-basis: 25%;
  margin-top: 0;
  margin-bottom: 24px;
  color: ${(props) => props.theme.black};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const ChooseInvestmentsShort = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    flex-basis: 25%;
    margin-top: 0;
    margin-bottom: 24px;
    color: ${(props) => props.theme.black};
  }
`;

const InvestmentMapColumn = styled.div`
  width: 100%;
  height: 500px;

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  .gmnoprint div {
    background: none !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 0;

    .gm-style {
      left: 20% !important;
    }
  }

  @media (max-width: 912px) {
    .gm-style {
      left: 70px !important;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: auto;

    .gm-style {
      left: 0 !important;
    }

    & > div {
      height: 410px;
    }
  }
`;

const InvestmentCheckboxColumn = styled.div`
  max-width: 224px;
  position: absolute;
  bottom: 94px;
  left: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.white};
  padding: 20px 25px 20px 20px;
  pointer-events: all;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    bottom: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    position: static;
    max-width: 100%;
    padding: 20px;
  }
`;

const MobileFiltersToggleButton = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${(props) => props.theme.black};
    cursor: pointer;
    width: 76.285%;
    box-sizing: border-box;
  }
`;

const MobileMapToggleButton = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 11px;
    font-weight: 700;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${(props) => props.theme.black};
    cursor: pointer;
    width: 20.285%;
    box-sizing: border-box;
  }
`;

const AdvancedFiltersButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${(props) =>
    props.isActive ? '#F5F5F5' : props.theme.white};
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: ${(props) => props.theme.black};
  padding: 24px 32px;
  margin-left: auto;
  cursor: pointer;

  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 20px 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 16px 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    background-color: #f5f5f5;
    margin-bottom: 0;
    box-sizing: border-box;
  }
`;

const AdvancedFiltersWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 24px;
  background-color: #f5f5f5;
  padding: 24px 24px 4px 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    padding: 20px 20px 0 20px;
    gap: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0px 20px 10px 20px;
    margin-top: -4px;
  }
`;

const AdvancedFilters = styled.div`
  display: flex;
  flex-direction: column;
  width: 445px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 345px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }

  &:last-of-type {
    width: 184px;
  }

  & > h3 {
    margin: 0 0 24px 0;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${(props) => props.theme.black};

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      margin-bottom: 20px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding-top: 14px;
    }
  }
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isOneColumn
      ? 'fit-content(100%)'
      : 'fit-content(50%) fit-content(50%)'};
  grid-column-gap: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: ${(props) =>
      props.isOneColumn ? 'fit-content(100%)' : 'repeat(4, 142px)'};
    white-space: nowrap;
    grid-template-areas: 'column-1 column-3 column-2 column-4';
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 18px;
  }
`;

const CheckboxField = styled(Row)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  label {
    margin-top: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    &:nth-child(1) {
      grid-area: column-1;
    }
    &:nth-child(2) {
      grid-area: column-2;
    }
    &:nth-child(3) {
      grid-area: column-3;
    }
    &:nth-child(4) {
      grid-area: column-4;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    align-items: ${(props) =>
      props.$smallMobileRowGap ? 'center' : 'flex-start'};
    margin-bottom: ${(props) => (props.$isWithoutBottomSpacing ? '0' : '10px')};

    label {
      width: 40%;
      word-wrap: break-word;
    }
  }
`;

const ContainerWrapper = styled(ContainerMain)`
  height: 100%;
  user-select: none;
  padding-bottom: 24px !important;

  .react-tabs__tab--selected {
    background-color: #f5f5f5;
  }

  *:focus {
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 24px !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 0 20px !important;
  }
`;

const InvestmentCheckboxColumnWrapper = styled(ContainerMain)`
  position: absolute;
  top: 0;
  left: 50%;
  height: 500px;
  padding-top: 50px !important;
  box-sizing: content-box;
  transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 0 !important;
    box-sizing: border-box;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    position: relative;
    height: auto;
    transform: none;
    top: 0;
    left: 0;
    padding: 0 !important;
  }
`;

const StyledTabList = styled(TabList)`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-top: 4px;
  margin-bottom: 0;
  border-bottom: 2px solid #f5f5f5;
  transition: margin 200ms;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const StyledTab = styled(Tab)`
  display: flex;
  align-items: center;
  padding: 24px 32px;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 20px 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    white-space: nowrap;
    padding: 16px 20px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  & > div {
    padding-top: 40px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding-top: 26px;
    }
  }
`;

const RoundedTriangle = styled(RoundedTriangleIcon)`
  transform: ${(props) =>
    props.$areAdvancedFiltersShown ? 'rotate(0deg)' : 'rotate(-180deg)'};
  transition: transform 0.2s ease;
`;

const UndoButton = styled.div`
  color: ${(props) => props.theme.primary3Color};
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: normal;
  margin: 2px 2px 1px 8px;
  opacity: 0.6;
  text-transform: none;
  border-bottom: 1px solid #6c6c6c;
  display: inline-block;
  line-height: 0.85;
`;

const StickyDesktopBar = styled(ContainerMain)`
  position: fixed;
  top: 88px;
  left: 0;
  border-top: 1px solid #c4c4c4;
  width: 100% !important;
  height: 100px;
  background-color: ${(props) => props.theme.white};
  z-index: 100;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 8px 0px #0000001a;
  padding-top: 10px !important;
  max-height: 81px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    top: 50px;
    height: 165px;
    max-height: 165px;
  }

  & > div {
    max-width: 1170px;
    margin-bottom: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      max-width: 970px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      row-gap: 12px;
      max-width: 728px;
    }
  }
`;

const InvestmentHeaderWrapper = styled.div`
  display: flex;
  gap: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 0 20px;
    gap: 0;
  }
`;

const StickyMobileBar = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$areBadgesShown ? '12px' : '0')};
  top: 49px;
  left: 0;
  border-top: 1px solid #c4c4c4;
  width: 100%;
  background-color: ${(props) => props.theme.white};
  z-index: 100;
  height: ${(props) =>
    props.areFiltersShown ? 'calc(100dvh - 80px)' : 'auto'};
  overflow: ${(props) => (props.areFiltersShown ? 'auto' : 'initial')};
  padding: 8px 20px;
  padding-bottom: ${(props) => (props.areFiltersShown ? '30px' : '8px')};
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const MobileFiltersBarModalWrapper = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.white};
  margin-top: -8px;
  position: relative;
  z-index: 1;
`;

const SearchButton = styled.button`
  all: unset;
  width: 100%;
  height: 61px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.red};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border-radius: 4px;
  margin-top: 12px;
  cursor: pointer;
`;

const MobileBarButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LocalCounter = styled.span`
  font-size: 22px;
  font-weight: 300;
  line-height: 22px;
  display: flex;
  align-items: center;
  height: 42px;
  color: ${(props) => props.theme.secondary13Color};
  text-transform: uppercase;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 27px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-transform: none;
  }
`;

const InvestmentTitle = styled.span`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    color: ${(props) => props.theme.secondary13Color};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const StrokeWrapper = styled.div`
  text-decoration: line-through;
`;

export {
  Wrapper,
  Container,
  RowContainer,
  ColumnContainer,
  SingleField,
  Margin,
  FieldWrapper,
  Row,
  InvestmentsContainer,
  InvestmentColumn,
  ChooseInvestments,
  ChooseInvestmentsShort,
  InvestmentRow,
  InvestmentCheckboxColumn,
  InvestmentMapColumn,
  CheckboxField,
  ScrollingAnchor,
  ScrollingAnchorAdvancedFilters,
  MobileFiltersToggleButton,
  AdvancedFiltersButton,
  AdvancedFiltersWrapper,
  AdvancedFilters,
  Features,
  ContainerWrapper,
  InvestmentCheckboxColumnWrapper,
  StyledTabList,
  StyledTab,
  StyledTabPanel,
  RoundedTriangle,
  UndoButton,
  StickyDesktopBar,
  StickyMobileBar,
  InvestmentHeaderWrapper,
  MobileFiltersBarModalWrapper,
  SearchButton,
  MobileBarButtonsWrapper,
  MobileMapToggleButton,
  LocalCounter,
  InvestmentTitle,
  StrokeWrapper,
};
