const RoundedTriangleIcon = (props = {}) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.66652 11.333L11.3332 11.333C11.5752 11.333 11.7985 11.2017 11.9165 10.9897C12.0339 10.7777 12.0272 10.5183 11.8985 10.313L8.56519 4.97967C8.44319 4.78501 8.22985 4.66634 7.99985 4.66634C7.76985 4.66634 7.55652 4.78501 7.43452 4.97967L4.10119 10.313C3.97252 10.5183 3.96585 10.7777 4.08319 10.9897C4.20119 11.2017 4.42452 11.333 4.66652 11.333Z'
      fill='#212121'
    />
  </svg>
);
export default RoundedTriangleIcon;
