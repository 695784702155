import { Component } from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { injectIntl } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import config from 'config';
import withSizes from 'utils/withSizes';

import Header from 'components/Header';
import HeaderMobile from 'components/HeaderMobile';
import Breadcrumbs from 'components/Breadcrumbs';
import ContactIcons from 'components/ContactIcons';
import Slider from 'components/Slider';
import QuickSearchForm from 'components/QuickSearchForm';
import ContactWithMap from 'components/ContactWithMap';
import FooterCopyright from 'components/FooterCopyright';
import GoToTop from 'components/GoToTop';
import translateMenu from 'utils/translateMenu';
import { VisibilityContainer } from 'utils/styles';
import intlShape from 'utils/shapes/intlShape';
import Head from 'next/head';

import { SliderItemShape } from 'components/Slider/Slider.shapes';
import { MapPointShape } from 'components/GoogleMaps/GoogleMaps.shapes';
import { ContactUsEntryShape } from 'components/content/ContactUsBlock/ContactUsBlock.shapes';
import GoogleMaps from 'components/GoogleMaps';
import { withRouter } from 'next/router';

import {
  HeaderWrapper,
  SearchWrapper,
  TopWrapper,
  ContactIconsWrapper,
  GoToTopWrapper,
  GlobalWrapper,
  ContentWrapper,
  IframeWrapper,
  GlobalStyle,
} from './PageLayout.styles';
import { socialLinks } from './PageLayout.data';
import { filterMapPoints, getInvestmentMapPoints } from './PageLayout.utils';
import { ContactShape } from './PageLayout.shapes';
import messages from './PageLayout.messages';

class PageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      isBottom: false,
      isSliderLoaded: false,
    };
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;
      const isBottom =
        window.scrollY > document.documentElement.scrollHeight - 800;

      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }

      if (isBottom !== this.state.isBottom) {
        this.setState({ isBottom });
      }
    });
  }

  onTopClick = () => {
    window.scrollTo(0, 0);
  };

  onSearchClick = (data) => {
    this.props.showLoader();
    this.props.router.push({
      pathname: '/wyszukiwarka',
      query: data,
    });
  };

  handleMarkerScroll = (slug) => {
    const investmentThumbnailElement = document.getElementById(slug);

    if (investmentThumbnailElement) {
      investmentThumbnailElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  onSliderLoaded = () => {
    this.setState({ isSliderLoaded: true });
  };

  render() {
    const {
      intl,
      breadcrumbs,
      children,
      contactItems,
      dronePictures,
      mapPoints,
      menu,
      contact,
      quickSearchFilters,
      hideQuickSearch,
      showContactData,
      sliderItems,
      staticMapUrl,
      showMap,
      hideBanner,
      hideFooter,
      favouritesCount,
      hideMobileBanner,
      onlyOfficesOnMap,
      contactPeople,
      onPositionChange,
      url,
      onImageLoad,
      buildingsMapPoints,
      isMapWithClusters,
      screenType,
      hideBreadcrumbOnMobile,
      isDesktopStickyBarShown,
    } = { ...this.props };
    const translatedMenu = translateMenu(menu, intl);
    const pointsOnMap = onlyOfficesOnMap
      ? filterMapPoints({ mapPoints, type: 'Office' })
      : getInvestmentMapPoints({ mapPoints });
    let pageTitle = breadcrumbs.pageTitle
      ? `${breadcrumbs.pageTitle} | ${config.title}`
      : config.title;
    const pageDescription = breadcrumbs.pageDescription
      ? breadcrumbs.pageDescription
      : config.description;
    const phone = contact.phone;
    const isSliderPresent = !_isEmpty(sliderItems);
    const isMobile = screenType === 'xs';

    const breadcrumbMap = () => {
      if (!buildingsMapPoints || !buildingsMapPoints.length) return null;

      return (
        <GoogleMaps
          points={buildingsMapPoints}
          staticMapUrl={this.props.staticMapUrl}
          onMarkerClick={({ slug }) => this.handleMarkerScroll(slug)}
          zoom={isMobile ? 11 : null}
          isMapWithClusters={isMapWithClusters}
          isMobileMapBigger
          isMapWithMobileModal
        />
      );
    };

    const breadcrumb = () => (
      <Breadcrumbs
        key='breadcrumbs'
        {...breadcrumbs}
        onImageLoad={onImageLoad}
        isWithoutPathParts={breadcrumbs.isWithoutPathParts}
        isImageSmall={breadcrumbs.isImageSmall}
        backgroundMap={breadcrumbMap()}
        hideOnMobile={hideBreadcrumbOnMobile}
      />
    );

    const slider = () => (
      <Slider
        key='slider'
        items={sliderItems}
        onSliderLoaded={this.onSliderLoaded}
      />
    );

    if (breadcrumbs.pageTitle && breadcrumbs.isPageTitleRaw) {
      pageTitle = breadcrumbs.pageTitle;
    }

    if (!this.state.isSliderLoaded && isSliderPresent) {
      return slider();
    }

    return (
      <>
        <Head>
          <title>{pageTitle}</title>
          <meta name='description' content={pageDescription} />
          {url && url.query && url.query.isNewest === 'false' ? (
            <meta name='robots' content='noindex' />
          ) : null}
          <meta property='og:title' content={pageTitle} />
          <meta
            property='og:image'
            content={breadcrumbs.imageUrl || config.metaOGImage}
          />
        </Head>
        <GlobalWrapper>
          <GlobalStyle />
          <HeaderWrapper key='header'>
            <VisibilityContainer $md $lg>
              <Header
                key='headerDesktop'
                menuItems={translatedMenu}
                socialLinksItems={socialLinks}
                favouritesCount={favouritesCount}
                isDesktopStickyBarShown={isDesktopStickyBarShown}
              />
            </VisibilityContainer>
            <VisibilityContainer $xs $sm>
              <HeaderMobile
                key='headerMobile'
                phone={phone}
                menuItems={translatedMenu}
                favouritesCount={favouritesCount}
                contactItems={contactItems}
                email={contact.mail}
                contactPeople={contactPeople}
                isDesktopStickyBarShown={isDesktopStickyBarShown}
              />
            </VisibilityContainer>
          </HeaderWrapper>
          {hideBanner ? null : (
            <VisibilityContainer
              $md
              $lg
              $xs={!hideMobileBanner}
              $sm={!hideMobileBanner}
            >
              <TopWrapper key='top'>
                {!isSliderPresent ? breadcrumb() : slider()}
              </TopWrapper>
              <VisibilityContainer $md $lg>
                {hideQuickSearch ? null : (
                  <SearchWrapper $hasMarginTop={isSliderPresent}>
                    <QuickSearchForm
                      {...quickSearchFilters}
                      onButtonClick={this.onSearchClick}
                    />
                  </SearchWrapper>
                )}
              </VisibilityContainer>
            </VisibilityContainer>
          )}
          <ContentWrapper>{children}</ContentWrapper>
          {showMap ? (
            <ContactWithMap
              key='contactMap'
              contactItems={contactItems}
              mapPoints={pointsOnMap}
              staticMapUrl={staticMapUrl}
              showContactData={showContactData}
              centered={onlyOfficesOnMap}
              onPositionChange={onPositionChange}
              showLoader={this.props.showLoader}
              isMapOfOnlyOffices={this.props.isMapOfOnlyOffices}
            />
          ) : null}
          {dronePictures && (
            <IframeWrapper>
              <Iframe
                id={config.locationIds.investmentMap}
                title={intl.formatMessage(messages.panoramicMap)}
                src={dronePictures}
                allowfullscreen
                webkitallowfullscreen
                mozallowfullscreen
              />
            </IframeWrapper>
          )}
          {!hideFooter && (
            <FooterCopyright key='copyright' socialLinksItems={socialLinks} />
          )}
          <GoToTopWrapper
            $isTop={this.state.isTop}
            $isBottom={this.state.isBottom}
          >
            <GoToTop onClick={this.onTopClick} />
          </GoToTopWrapper>
          <ContactIconsWrapper
            key='contactIconsDesktop'
            $center={!isSliderPresent}
          >
            <ContactIcons mail={contact.mail} phone={contact.phone} />
          </ContactIconsWrapper>
        </GlobalWrapper>
      </>
    );
  }
}

PageLayout.propTypes = {
  intl: intlShape.isRequired,
  breadcrumbs: PropTypes.object,
  children: PropTypes.node,
  contact: ContactShape,
  contactItems: PropTypes.arrayOf(ContactUsEntryShape),
  dronePictures: PropTypes.node,
  footerMenu: PropTypes.object,
  hideBanner: PropTypes.bool,
  hideBreadcrumbOnMobile: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideMobileBanner: PropTypes.bool,
  hideQuickSearch: PropTypes.bool,
  imageLoaded: PropTypes.bool,

  isDesktopStickyBarShown: PropTypes.bool,

  isMapOfOnlyOffices: PropTypes.bool,
  mapPoints: PropTypes.arrayOf(MapPointShape),

  menu: PropTypes.array,
  onlyOfficesOnMap: PropTypes.bool,
  quickSearchFilters: PropTypes.object,
  router: PropTypes.object,
  screenType: PropTypes.string,
  showContactData: PropTypes.bool,
  showMap: PropTypes.bool,
  sliderItems: PropTypes.arrayOf(SliderItemShape),
  staticMapUrl: PropTypes.string,
  onPositionChange: PropTypes.func,
};

PageLayout.defaultProps = {
  breadcrumbs: {},
  children: null,
  contact: {},
  contactItems: [],
  dronePictures: null,
  footerMenu: [],
  hideQuickSearch: false,
  mapPoints: [],
  menu: [],
  quickSearchFilters: {},
  showContactData: true,
  sliderItems: null,
  staticMapUrl: null,
  showMap: false,
  hideFooter: false,
  hideMobileBanner: false,
  hideBanner: false,
  onlyOfficesOnMap: false,
  onPositionChange: () => {},
  imageLoaded: false,
  hideBreadcrumbOnMobile: false,
  screenType: 'lg',
  router: {},
  isDesktopStickyBarShown: false,
  isMapOfOnlyOffices: false,
};

export default withRouter(withSizes()(injectIntl(PageLayout)));
